import React, { useCallback, useEffect, useState } from 'react';
import logo from '../../Assets/images/logo.webp'
import instagram from '../../Assets/images/icons/instagram.svg'
import twitter from '../../Assets/images/icons/twitter.svg'
import telegram from '../../Assets/images/icons/telegram.svg'
import closeicon from '../../Assets/images/close.svg'
import { useRefs } from '../../Pages/RefsContext';

const Header = () => {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [className, setClassName] = useState('');

    const [price, setPrice] = useState(0);

    const fetchPrice = useCallback(async () => {
        fetch('https://solana-gateway.moralis.io/token/mainnet/5UDDHJKzkPf84f6FjqfprqvsQhdDw9o3t1y1xqEahsGD/price', {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjVjZjMzYmIyLTEwM2YtNDcwOS1hYzY3LTc2MjUyM2E1ZTEzMSIsIm9yZ0lkIjoiMzg0OTYxIiwidXNlcklkIjoiMzk1NTUxIiwidHlwZUlkIjoiY2NhNDUzZjItMThiOS00OTM2LWFmYTgtMWIzOGJiMGE0NmMyIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTE1MzA0MzksImV4cCI6NDg2NzI5MDQzOX0.cCfl4VSwCYyoS3UIW7XxNBS7p8U4AOs1pIAvtyZNA-Y'
            }
            })
            .then(response => response.json())
            .then(data => {
                if (data.usdPrice <= 0.000001) {
                    fetchPrice();
                    return;
                }
                setPrice(data.usdPrice);
            })
            .catch(error => {
                console.error('Error:', error);
                fetchPrice()
            });
    }, [])

    useEffect(() => {
        fetchPrice();
    }, [fetchPrice]);

    const { homeRef, whoisRef, slerfsaviournomicsRef, topaddressRef } = useRefs();

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };


    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos === 0) {
                setClassName('');
            } else if (prevScrollPos < currentScrollPos && currentScrollPos > 10) {
                setClassName('ss__sticky-menu');
            } else if (prevScrollPos > currentScrollPos && currentScrollPos < 10) {
                setClassName('');
            } else {
                setClassName('ss__sticky-menu ss__sticky-menu-show');
            }

            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]); // handleScroll is defined within the useEffect, so no dependency warning
    // eslint-disable-next-line
    const [menuVisible, setMenuVisible] = useState(false);

    // Function to show the mobile menu
    const showMenu = () => {
        setMenuVisible(true);
        document.body.classList.add('mobile-menu-visible');
    };

    // Function to hide the mobile menu
    const hideMenu = () => {
        setMenuVisible(false);
        document.body.classList.remove('mobile-menu-visible');
    };

    return (
        <>
            {/* Header Section Start */}
            <header>
                <div id="sticky-header" className={`ss__header-main transparent-header ${className}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mobile-nav-toggler" onClick={showMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <nav className="ss__menu-nav">
                                    <div className="logo">
                                        <a href="#!">
                                            <img src={logo} width="177" height="39" alt="Slerf Saviour Logo" />
                                        </a>
                                    </div>
                                    <div className="ss__menu-navbar-wrap ss__menu-main d-none d-xl-flex">
                                        <ul className="navigation">
                                            <li onClick={() => scrollToRef(homeRef)}><a href="#!">Home</a></li>
                                            <li onClick={() => scrollToRef(whoisRef)}><a href="#!">Who Is Slerf Saviour</a></li>
                                            <li onClick={() => scrollToRef(slerfsaviournomicsRef)}><a href="#!">Slerf Saviour Nomics</a></li>
                                            <li onClick={() => scrollToRef(topaddressRef)}><a href="#!">topaddress</a></li>
                                        </ul>
                                    </div>
                                    <div className="ss__menu-action">
                                        <ul className="list-wrap">
                                            <li className="header-btn">
                                                <span>Price: ${price.toFixed(6)}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                                {/* Mobile Menu */}
                                <div className="ss__mobile-menu">
                                    <nav className="ss__menu-box">
                                        <div className="close-btn" onClick={hideMenu}>
                                            <img src={closeicon} width="20" height="20" alt="Close Icon" />
                                        </div>
                                        <div className="nav-logo">
                                            <a href="#!">
                                                <img src={logo} width="177" height="39" alt="Slerf Saviour Logo" />
                                            </a>
                                        </div>
                                        <div className="ss__mobile-menu-outer">
                                            <ul className="navigation">
                                                <li onClick={() => { scrollToRef(homeRef); hideMenu(); }}><a href="#!">Home</a></li>
                                                <li onClick={() => { scrollToRef(whoisRef); hideMenu(); }}><a href="#!">Who Is Slerf Saviour</a></li>
                                                <li onClick={() => { scrollToRef(slerfsaviournomicsRef); hideMenu(); }}><a href="#!">Slerf Saviour Nomics</a></li>
                                                <li onClick={() => { scrollToRef(topaddressRef); hideMenu(); }}><a href="#!">topaddress</a></li>
                                            </ul>
                                        </div>
                                        <div className="ss__social-links">
                                            <ul className="list-wrap">
                                                <li><a href="https://www.instagram.com/slerfsaviour/" target='_blank' rel="noreferrer"><img src={instagram} width="30" height="30" alt="instagram icon" /></a></li>
                                                <li><a href="https://t.me/slerfsaviour" target='_blank' rel="noreferrer"><img src={telegram} width="30" height="30" alt="telegram icon" /></a></li>
                                                <li><a href="https://twitter.com/slerfsaviour" target='_blank' rel="noreferrer"><img src={twitter} width="30" height="30" alt="twitter icon" /></a></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="ss__mobile-menu-bg" onClick={hideMenu}></div>
                                {/* Mobile Menu End */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* Header Section End */}
        </>
    )
}

export default Header