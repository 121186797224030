import React from 'react';
import instagram from '../../Assets/images/icons/instagram.svg'
import twitter from '../../Assets/images/icons/twitter.svg'
import telegram from '../../Assets/images/icons/telegram.svg'

const Footer = () => {
    return (
        <>
            {/* Footer Section Start */}
            <footer className='ss__footer'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="ss__footer-copyright-text">
                                <p>Copyright © {new Date().getFullYear()} - All Rights Reserved By <a href='/' target='_blank' rel="noreferrer">Slerf Saviour</a></p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="ss__footer-card text-center text-md-end">
                                <div className="ss__footer-social">
                                    <a href="https://twitter.com/slerfsaviour" target='_blank' rel="noreferrer"><img src={twitter} width="30" height="30" alt="twitter icon" /></a>
                                    <a href="https://t.me/slerfsaviour" target='_blank' rel="noreferrer"><img src={telegram} width="30" height="30" alt="telegram icon" /></a>
                                    <a href="https://www.instagram.com/slerfsaviour/" target='_blank' rel="noreferrer"><img src={instagram} width="30" height="30" alt="instagram icon" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section End */}
        </>
    )
}

export default Footer