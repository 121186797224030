import { useEffect } from 'react';

const useGlobalClickListener = (callback) => {
    useEffect(() => {
        const handleClick = (event) => {
            const { target } = event;
            if (target.tagName === 'A' || target.tagName === 'BUTTON' || target.classList.contains('mobile-nav-toggler') || target.classList.contains('close-btn')) {
                callback();
            }
        };

        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [callback]);
};

export default useGlobalClickListener;