import React, { useCallback, useEffect, useState } from 'react';
import logo from '../../Assets/images/logo.webp'
import bordtitle from '../../Assets/images/bordtitle.webp'
import potImg from '../../Assets/images/pot.webp'
import { useRefs } from '../../Pages/RefsContext'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Banner = () => {
    const { homeRef } = useRefs();
    const [value, setValue] = useState('5UDDHJKzkPf84f6FjqfprqvsQhdDw9o3t1y1xqEahsGD');
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false); // Reset the copied state after 3 seconds
        }, 1000);
    };

    const [marketCap, setMarketCap] = useState(0);

    const fetchPrice = useCallback(async () => {
        fetch('https://solana-gateway.moralis.io/token/mainnet/5UDDHJKzkPf84f6FjqfprqvsQhdDw9o3t1y1xqEahsGD/price', {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjVjZjMzYmIyLTEwM2YtNDcwOS1hYzY3LTc2MjUyM2E1ZTEzMSIsIm9yZ0lkIjoiMzg0OTYxIiwidXNlcklkIjoiMzk1NTUxIiwidHlwZUlkIjoiY2NhNDUzZjItMThiOS00OTM2LWFmYTgtMWIzOGJiMGE0NmMyIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTE1MzA0MzksImV4cCI6NDg2NzI5MDQzOX0.cCfl4VSwCYyoS3UIW7XxNBS7p8U4AOs1pIAvtyZNA-Y'
            }
            })
            .then(response => response.json())
            .then(data => {
                console.log(data.usdPrice);
                if (data.usdPrice <= 0.000001) {
                    fetchPrice();
                    return;
                }
                setMarketCap(data.usdPrice * 17_000_000_000);
            })
            .catch(error => {
                console.error('Error:', error);
                fetchPrice()
            });
    }, [])

    useEffect(() => {
        fetchPrice();
    }, [fetchPrice]);


    return (
        <>
            {/* Banner Section Start */}
            <section ref={homeRef} className="ss__banner">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12">
                            <div data-aos="fade-up" data-aos-duration="3000" className="ss__banner-content">
                                <img src={logo} alt="Slerf Saviour Logo" width={150} height={150} />
                                <h1 className="title mb-0">Slerf Saviour</h1>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="3000" className='roadmapTree'>
                        <div className={`item ${marketCap <= 100_000 && "push"}`}>
                            {/* <strong>Moon</strong> */}
                            <strong>$100K</strong>
                        </div>
                        <div className={`item ${(marketCap > 100_000 && marketCap <= 500_000) && "push"}`}>
                            {/* <strong>The Next Bome</strong> */}
                            <strong>$500K</strong>
                        </div>
                        <div className={`item ${(marketCap > 500_000 && marketCap <= 1_000_000) && "push"}`}>
                            {/* <strong>Unstoppable</strong> */}
                            <strong>$1M</strong>
                        </div>
                        <div className={`item ${(marketCap > 1_000_000 && marketCap <= 5_000_000) && "push"}`}>
                            {/* <strong>Slerf</strong> */}
                            <strong>$5M</strong>
                        </div>
                        <div className={`item ${marketCap > 10_000_000 && "push"}`}>
                            {/* <strong>Slerf Saviour</strong> */}
                            <strong>$10M</strong>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="3000" className='bordTitle'>
                        <div className='bordTitleInner'>
                            <img className='priceBord' src={bordtitle} alt="bordtitle" width={210} height={260} />
                            <span>${Number(marketCap.toFixed(2)).toLocaleString()}</span>
                            <a href="https://www.dextools.io/app/en/solana/pair-explorer/4kPmAKQXbGvyj4pf8AonzjzWN4JryZX8oXKouFrwvwtV" target='_blank' rel="noreferrer" className='potImg'><img src={potImg} alt="bordtitle" width={290} height={140} /></a>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="3000" className='contractAddress'>
                        <div className='contractAddressInner'>
                            <input readOnly value={value} onChange={({ target: { value } }) => setValue(value)} aria-label="Contract Address" placeholder={value} />
                            <CopyToClipboard text={value} onCopy={handleCopy}>
                                <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 96 96" viewBox="0 0 96 96" id="copy"><circle cx="48" cy="48" r="47.5" fill="#F16C6B"></circle><g><path fill="#FFF" d="M30.8,35.7c0-3.3,2.7-5.9,5.9-5.9h24.6v-3.6c0-1.1-0.9-2-2-2H26.2c-1.1,0-2,0.9-2,2v34c0,1.1,0.9,2,2,2h4.5   V35.7z"></path><path fill="#FFF" d="M69.8,33.8H36.7c-1.1,0-2,0.9-2,2v34c0,1.1,0.9,2,2,2h33.1c1.1,0,2-0.9,2-2v-34   C71.7,34.6,70.9,33.8,69.8,33.8z M60.9,61.6H45.8c-1.1,0-2-0.9-2-2s0.9-2,2-2h15.1c1.1,0,2,0.9,2,2S62,61.6,60.9,61.6z M60.9,47.8   H45.8c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2h15.1c1.1,0,2,0.9,2,2C62.9,47,62,47.8,60.9,47.8z"></path></g></svg>
                            </CopyToClipboard>
                            {copied ? <span className='copied'>Copied!</span> : null}
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner Section End */}
        </>
    )
}

export default Banner