// RefsContext.jsx

import { createContext, useContext, useRef } from 'react';

export const RefsContext = createContext();

export const useRefs = () => useContext(RefsContext);

export const RefsProvider = ({ children }) => {
    const homeRef = useRef(null);
    const whoisRef = useRef(null);
    const slerfsaviournomicsRef = useRef(null);
    const topaddressRef = useRef(null);

    const value = {
        homeRef,
        whoisRef,
        slerfsaviournomicsRef,
        topaddressRef,
    };

    return (
        <RefsContext.Provider value={value}>
            {children}
        </RefsContext.Provider>
    );
};
