import React from 'react'
import { useRefs } from '../../Pages/RefsContext'
import bathimg from '../../Assets/images/bath.webp'

const Whois = () => {

    const { whoisRef } = useRefs();
    return (
        <>
            {/* Whois Section Start */}
            <section ref={whoisRef} className="whoisMain">
                <div data-aos="fade-right" data-aos-duration="3000" className='lineSectionbar'></div>
                <div className="container">
                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <div data-aos="fade-right" data-aos-duration="3000" className='infobox'>
                                <h2>Who is Slerf Saviour</h2>
                                <p>In this reimagined tale, Slerf Saviour the sloth, renowned for his slow pace yet captivating charm within the Amazon's green expanse, dreams. Propelled by a sudden spark of ambition, he embarks on an epic quest, defying his natural speed. Through rivers and over trees, with each purposeful step, he follows the moon. His journey, spanning weeks, culminates in lunar triumph, marking Slerf Saviour as a starry frontiers person among passivities. His story, a beacon across realms, champions the notion that with unchangeable willpower, even the most lagging can touch the stars.</p>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div data-aos="fade-left" data-aos-duration="3000" className='imgbox'>
                                <img src={bathimg} alt="bathimg" width={515} height={455} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Whois Section End */}
        </>
    )
}
export default Whois