import React from 'react'
import { useRefs } from '../../Pages/RefsContext'

const Slerfsaviournomics = () => {

    const { slerfsaviournomicsRef } = useRefs();
    return (
        <>
            {/* Slerfsaviournomics Section Start */}
            <section ref={slerfsaviournomicsRef} className="slerfsaviournomicsMain">
                <div data-aos="fade-up" className='lineSectionbar'></div>
                <div className="container">
                    <div className='row'>
                        <div className='col-12'>
                            <div className='infobox'>
                                <h2 data-aos="fade-up" className='titleMain'>Slerf Saviour Nomics</h2>
                                <div data-aos="fade-up" className='infoboxInner'>
                                    <div className="row">
                                        <div className='col-6 col-sm-6'>
                                            <div data-aos="fade-up" className='item'>
                                                <h3>Total Supply</h3>
                                                <p>$17 Billion</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-sm-6'>
                                            <div data-aos="fade-up" className='item'>
                                                <h3>50% LP</h3>
                                                <p>50% Presale</p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-sm-6'>
                                            <div data-aos="fade-up" className='item'>
                                                <h3>LP Burned</h3>
                                                <p>
                                                    <a href="https://solscan.io/tx/ffuCE7LA47YTDKb6GdWvLPu4vTHTcxYu6wkkhKnopciFybet6CXfxecZYwMvcyNGhd7wdzJzzmWgGt2YcXYyL3t" target='_blank' rel="noreferrer">
                                                        <span>Proof</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="Link"><path d="M21,11a1,1,0,0,0-1,1v8H4V4h8a1,1,0,0,0,0-2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V12A1,1,0,0,0,21,11Z" fill="#ffffff" class="color000000 svgShape"></path><path d="M21.382,2.077A.991.991,0,0,0,21,2H16a1,1,0,0,0,0,2h2.586l-7.293,7.293a1,1,0,1,0,1.414,1.414L20,5.414V8a1,1,0,0,0,2,0V3a1.01,1.01,0,0,0-.077-.382A1,1,0,0,0,21.382,2.077Z" fill="#ffffff" class="color000000 svgShape"></path></svg>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-sm-6'>
                                            <div data-aos="fade-up" className='item'>
                                                <h3>Ownership</h3>
                                                <p>Revoked</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Slerfsaviournomics Section End */}
        </>
    )
}
export default Slerfsaviournomics