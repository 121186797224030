
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import './Assets/css/style.css'
import './Assets/css/common.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import useAudioPlayer from './Components/UseAudioPlayer';
import useGlobalClickListener from './Components/useGlobalClickListener';
import clickSound from './Assets/audio/click.mp3';


const App = () => {

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  const playClickSound = useAudioPlayer(clickSound);
  useGlobalClickListener(playClickSound);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;