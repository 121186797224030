import React from 'react'
import Banner from '../../Components/Banner/Banner'
import Whois from '../../Components/Whois/Whois'
import Slerfsaviournomics from '../../Components/Slerfsaviournomics/Slerfsaviournomics'
import TopAddress from '../../Components/TopAddress/TopAddress'
const Home = () => {
    return (
        <>
            <Banner />
            <Whois />
            <Slerfsaviournomics />
            {/* <TopAddress /> */}
        </>
    )
}

export default Home