import { useRef } from 'react';

const useAudioPlayer = (soundFile) => {
    const audioRef = useRef(new Audio(soundFile));

    const playSound = () => {
        audioRef.current.play().catch(e => console.error("Audio play failed:", e));
    };

    return playSound;
};

export default useAudioPlayer;
